import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		currentStep: 1,
		renderSvg: null,
		tipologie: null,
		tipologia: [],
		width: null, // 2 step
		height: null,
		left_space: null,
		architrave: null,
		right_space: null,
		depth: null,
		manti: [], // 3 step
		idModel: "0",
		floors: [],
		walls: [],
		typesImg: [],
		urlParams: "",
		selManto: null,
		selFloor: null,
		selWall: null,
		finiture: [],
		colori: [], // 4 step
		swatches: [],
		ralTable: [],
		idColor: "0",
		selFinitura: null,
		selTipologia: null,
		selColore: "",
		oldRal: null,
		selOptions: [], // 5 step
		opzioni: [],
	},

	mutations: {
		incrementStep(state) {
			state.currentStep++;
		},
		decrementStep(state) {
			state.currentStep--;
		},
		updateWidth(state, value) {
			state.width = value;
		},
		updateHeight(state, value) {
			state.height = value;
		},
		updateLeftSpace(state, value) {
			state.left_space = value;
		},
		updateArchitrave(state, value) {
			state.architrave = value;
		},
		updateRightSpace(state, value) {
			state.right_space = value;
		},
		updateDepth(state, value) {
			state.depth = value;
		},
		updateManti(state, value) {
			state.urlParams = "";
			value.forEach((item, index) => {
				if(index==0){	// first element
						state.urlParams += "idManti[]=" + item["id"];
					}else{
						state.urlParams += "&idManti[]=" + item["id"];
					}
			});
			state.manti = value;
		},
		updateManto(state, value) {
			state.selManto = value;
		},
		updateDisegnoManto(state, value) {
			state.selManto.disegno = value;
		},
		updateIdModel(state, value) {
			state.idModel = value;
		},
		updateFloors(state, value) {
			state.floors = value;
		},
		updateFloor(state, value) {
			state.selFloor = value;
		},
		updateWalls(state, value) {
			state.walls = value;
		},
		updateWall(state, value) {
			state.selWall = value;
		},
		updateTypesImg(state, value) {
			state.typesImg = value;
		},
		updateFiniture(state, value) {
			state.finiture = value;
		},
		updateFinitura(state, value) {
			state.selFinitura = value;
		},
		updateTipologie(state, value) {
			state.tipologie = value;
		},
		updateTipologia(state, value) {
			state.selTipologia = value;
		},
		updateColori(state, value) {
			state.colori = value;
		},
		updateFuoriStd(state, value) {
			if (value) {
				state.swatches = value.colorpicker;
				state.ralTable = value.ralTable;
			} else {
				state.swatches = [];
				state.ralTable = [];
			}
		},
		updateColore(state, value) {
			state.selColore = value;
		},
		updateIdColor(state, value) {
			state.idColor = value;
		},
		updateCustomColore(state, value) {
			let customCrl = {
				hex: value.hex,
				idClr: "-1",
				name: "Colore RAL ",
				ral: value.ral,
			};
			state.selColore = customCrl;
		},
		updateOldRal(state, value) {
			state.oldRal = value;
		},
		updateSelOptions(state, value) {
			state.selOptions = value;
		},
		updateDoorWidth(state, value) {
			let door = state.selOptions.filter((opt) => opt.key == "door")[0];
			if (typeof door !== "undefined") door.selWidth = value;
		},
		updateDoorAlign(state, value) {
			let door = state.selOptions.filter((opt) => opt.key == "door")[0];
			if (typeof door !== "undefined") door.selAlign = value;
		},
		updateDoorHandle(state, value) {
			let door = state.selOptions.filter((opt) => opt.key == "door")[0];
			if (typeof door !== "undefined") door.selHandle = value;
		},
		updateObloModel(state, value) {
			let oblo = state.selOptions.filter((opt) => opt.key == "oblo")[0];
			if (typeof oblo !== "undefined") oblo.selModel = value;
		},
		updateGrigliaModel(state, value) {
			let griglia = state.selOptions.filter((opt) => opt.key == "griglia")[0];
			if (typeof griglia !== "undefined") griglia.selModel = value;
		},
		updateSezioneVetrataHeight(state, value) {
			let sezioneVetrata = state.selOptions.filter(
				(opt) => opt.key == "sezioneVetrata"
			)[0];
			if (typeof sezioneVetrata !== "undefined")
				sezioneVetrata.selHeight = value;
		},
		updateObDistrModel(state, value) {
			let oblo = state.selOptions.filter((opt) => opt.key == "oblo")[0];
			if (typeof oblo !== "undefined") oblo.selDistr = value;
		},
		updateGrDistrModel(state, value) {
			let griglia = state.selOptions.filter((opt) => opt.key == "griglia")[0];
			if (typeof griglia !== "undefined") griglia.selDistr = value;
		},
		updateSezVetrataModel(state, value) {
			let sezioneVetrata = state.selOptions.filter(
				(opt) => opt.key == "sezioneVetrata"
			)[0];
			if (typeof sezioneVetrata !== "undefined")
				sezioneVetrata.selDistr = value;
		},
		updateOpzioni(state, value) {
			state.opzioni = value;
		},
		updateObloRows(state, value) {
			let oblo = state.opzioni.filter((opz) => opz.key == "oblo")[0];
			if (typeof oblo !== "undefined") oblo.rows = value;
		},
		updateGrigliaRows(state, value) {
			let griglia = state.opzioni.filter((opz) => opz.key == "griglia")[0];
			if (typeof griglia !== "undefined") griglia.rows = value;
		},
		
		updateRenderSvg(state, value) {
			state.renderSvg = value;
		},
	},

	actions: {},
	modules: {},
});
