import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import axios from "axios";
import qs from "qs";

Vue.config.productionTip = false;

//global variables
Vue.mixin({
  data: () => ({
    publicPath: process.env.BASE_URL,
    apiRoot: '/api/v1.0/ajax/', // server-side API root
    // apiRoot: 'http://127.0.0.1:8000/api/v1.0/ajax/', //local-side API root
  }),
});

//global filters
Vue.filter("lowercase", function (value) {
  if (!value) return "";
  return value.toLowerCase();
});
Vue.filter("uppercase", function (value) {
  if (!value) return "";
  return value.toUpperCase();
});
Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
window.axios = axios;
window.qs = qs;

new Vue({
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
