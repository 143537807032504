<template>
		<v-container fluid>
		<v-row justify-content="space-between">
				<v-col cols="12" md="8">

						<v-card class="" >
								<div class="svgContainer" v-if="currentStep==3" v-html="renderSvg"></div>
								<v-card-text>
										<v-container>
												<v-row>
														<v-col cols="12" md="6">
																<h2>{{"Il mio portone" | uppercase}}</h2>
																<p class="mt5">
																		Tipologia: {{selTipologia ? selTipologia.name : null}}<br>
																		Lunghezza (L): {{width}}mm<br>
																		Altezza (H): {{height}}mm<br>
																		<span v-if="left_space">Spalletta Sinistra (SS): {{left_space}}mm<br></span>
																		<span v-if="right_space">Spalletta Destra (SD): {{right_space}}mm<br></span>
																		<span v-if="architrave">Architrave (A): {{architrave}}mm<br></span>
																		<span v-if="depth">Profondità (Z): {{depth}}mm<br></span>
																</p>
														</v-col>
														<v-col cols="12" md="6">
																<h2 v-if="finiture[idModel]">{{finiture[idModel].name | uppercase}}</h2>
																<p v-if="finiture[idModel]">
																		{{finiture[idModel].desc}}
																</p>
														</v-col>
												</v-row>
										</v-container>
								</v-card-text>
						</v-card>

				</v-col>
				<v-col cols="12" md="4">
						
						<v-expansion-panels v-model="panel" multiple>
								<v-expansion-panel>
										<v-expansion-panel-header class="like-other-titles">Tipologia di pannelli</v-expansion-panel-header>
										<v-expansion-panel-content class="scroll">
												<v-radio-group v-model="idModel" >
														<template v-for="(finitura, n) in finiture">
																<v-row :key="`${n}-finitura`">
																		<v-col cols="9">
																				<h3><v-radio :value="`${n}`" :label="finitura.name | uppercase" @change="changeModel(finitura.idType, n)"></v-radio></h3>
																				<p class="mt-5">{{finitura.desc}}</p>
																		</v-col>
																		<v-col cols="3">
																				<img v-if="finitura.img" :src="finitura.img" :alt="finitura.name" width="100%">
																		</v-col>
																</v-row>
																<v-divider :key="`${n}-sep`"></v-divider>
														</template>    
												</v-radio-group>
										</v-expansion-panel-content>
								</v-expansion-panel>

								<v-expansion-panel>
										<v-expansion-panel-header class="like-other-titles">Colore e tipologia di pareti esterne</v-expansion-panel-header>
										<v-expansion-panel-content>
												<v-radio-group v-model="idWall" >
														<v-row>
																<template v-for="(wall, n) in walls">  
																		<v-col :key="`${n}-wall`" cols="12" xs="6" sm="4" md="3" class="beautyRadio">
																				<div :style="`width:60px; height:50px; background: #${wall.hex}`" :alt="wall.name" :title="wall.name" >
																						<img v-if="wall.thumb" :src="`${publicPath+wall.thumb}`" height="50px">
																				</div>
																				<v-radio :value="`${n}`" @change="changeWall(wall)"></v-radio>
																				<span>{{wall.name}}</span>
																		</v-col>
																</template>  
														</v-row>
												</v-radio-group>
										</v-expansion-panel-content>
								</v-expansion-panel>
								
								<v-expansion-panel>
										<v-expansion-panel-header class="like-other-titles">Tipologia di pavimentazione</v-expansion-panel-header>
										<v-expansion-panel-content>
												<v-radio-group v-model="idFloor" >                      
														<v-row>
																<template v-for="(floor, n) in floors">  
																		<v-col :key="`${n}-floor`" cols="12" xs="6" sm="4" md="3" class="beautyRadio">
																				<div :style="`width:60px; height:50px; background: #${floor.hex}`" :alt="floor.name" :title="floor.name" >
																						<img v-if="floor.thumb" :src="`${publicPath+floor.thumb}`" height="50px">
																				</div>
																				<v-radio :value="`${n}`" @change="changeFloor(floor)"></v-radio>
																				<span>{{floor.name}}</span>
																		</v-col>
																</template>  
														</v-row>
												</v-radio-group>
										</v-expansion-panel-content>
								</v-expansion-panel>
						</v-expansion-panels>
				</v-col>
		</v-row>
</v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
		props: { },
		data: () => ({
				panel: [0, 1, 2],
				accordion: [ 'Finiture', 'Pareti Esterne', 'Pavimentazione Esterna'],
				//idModel: '0',
				// floors: [],
				idFloor: '0',
				// walls: [],
				idWall: '0',
		}),
		computed: {
				idModel: {
						get() { return this.$store.state.idModel },
						set (value) { this.$store.commit('updateIdModel', value) }
				},
				floors: {
						get() { return this.$store.state.floors },
						set (value) { this.$store.commit('updateFloor', value) }
				},
				walls: {
						get() { return this.$store.state.walls },
						set (value) { this.$store.commit('updateWall', value) }
				},
				...mapState(['currentStep', 'renderSvg', 'width', 'height', 'left_space', 'right_space', 'architrave', 'depth', 'finiture', 'selTipologia', 'tipologia'])
		},
		methods: {
				async changeModel(idType, n) {
						this.$emit('waitingBtn', true)	
						// console.log(this.$store.state.finiture[n]);
						this.$store.commit('updateFinitura', this.$store.state.finiture[n])

						let getColors = await window.axios.get(this.apiRoot+'colori?idType='+idType+'&'+this.$store.state.urlParams)
						// console.log(getColors.data)
						this.$store.commit('updateColori', getColors.data.colori)
						this.$store.commit('updateColore', getColors.data.colori[0])
						if(typeof getColors.data.fuoriStd !== 'undefined') {
								this.$store.commit('updateFuoriStd', getColors.data.fuoriStd)
						}
						else {
								this.$store.commit('updateFuoriStd', null)
						}
						//resetto l'index del colore selezionato per step 3 e seleziono il manto in base al colore di dafult
						this.$store.commit('updateIdColor', "0")

						//scelgo manto/finitura in base ai dati ricevuti finora
						window.axios.get(this.apiRoot+'manti/byclr/'+this.$store.state.selColore.idClr+'?h='+this.height+'&idType='+this.$store.state.selFinitura.idType+'&'+this.$store.state.urlParams).then(response => {	
							this.$store.commit('updateManto', response.data)
							//opzioni
							window.axios.get(this.apiRoot+'opzioni', {
									params: {
											tipologia: this.$store.state.selTipologia,
											quadrettato: (this.$store.state.selFinitura.BugnaH>0 || this.$store.state.selFinitura.BugnaL>0)?1:0,
											manto: this.$store.state.selManto, 
											w: this.width,
											l: this.left_space,
											r: this.right_space,
											a: this.architrave,
											d: this.depth,
									},
									paramsSerializer: params => {
											return window.qs.stringify(params)
									}}
							).then(response => {
									this.$store.commit('updateOpzioni', response.data)
							})

							this.$store.commit('updateSelOptions', []) // reset se torni indietro dallo step 4
							
							this.$emit('updateSvg')
							this.$emit('waitingBtn', false)
						})
				},
				changeFloor(floor) {
						this.$store.commit('updateFloor', floor)
						this.$emit('updateSvg')
				},
				changeWall(wall) {
						this.$store.commit('updateWall', wall)
						this.$emit('updateSvg')
				},
		},
		created() {
				window.axios.get(this.apiRoot+'floors')
				.then(response => {
						this.$store.commit('updateFloors', response.data)
						this.$store.commit('updateFloor', response.data[0])
				})
				window.axios.get(this.apiRoot+'walls')
				.then(response => {
						this.$store.commit('updateWalls', response.data)
						this.$store.commit('updateWall', response.data[0])
				})
		}
}
</script>
<style scoped>
.like-other-titles{ 
		font-size: 1.25rem;
		font-weight: 500;
		letter-spacing: 0.0125em;
}
</style>