<template>
	<div>
		<!-- Using hide-overlay below allows for clicking while progress showing -->
		<v-dialog
			v-model="dialog"
			persistent
			:width="options.width"
			v-bind:style="{ zIndex: options.zIndex }"
		>
			<v-card :color="options.color" dark>
				<v-card-text>
					{{ message }}
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"
					></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-snackbar
			v-model="snackbarVisible"
			:left="snackbar.location === 'left'"
			:right="snackbar.location === 'right'"
			:top="snackbar.location === 'top'"
			:bottom="snackbar.location === 'bottom'"
			:color="snackbar.color"
			:multi-line="snackbar.mode === 'multi-line'"
			:timeout="snackbar.timeout"
			:vertical="snackbar.mode === 'vertical'"
		>
			{{ snackbar.message }}

            <template v-if="snackbar.closebtn" v-slot:action="{ attrs }">
                <v-btn v-bind="attrs" icon @click="hideSnackbar"><v-icon>mdi-close</v-icon></v-btn>
            </template>
		</v-snackbar>
	</div>
</template>

<script>
/**
 * Vuetify DialogLoader Component with SnackBar to show status
 * This component use the Vuetify Dialog Loader to show loading dialog, with optional SnackBar notifications (snackbar disabled by default)
 * Based on https://vuejsexamples.com/vuetify-dialog-loader-with-snackbar-component/
 **/
export default {
	data: () => ({
		dialog: false,
		message: null,
		options: {
			color: 'primary',
			width: 290,
			zIndex: 200
		},
		snackbarVisible: false,
		snackbar: {
			enabled: false,
			color: 'success',
			mode: 'multi-line',
			timeout: 7000,
			message: 'Success!',
			callback: null,
            location: 'bottom',
            closebtn: true,
		}
	}),
	methods: {
		/**
		 * Show loader without any callbacks or timeout (must be manually hidden)
		 * @param {string} message
		 * @param {object} options
		 */
		show(message, options) {
			this.dialog = true;
			this.message = message;
			this.options = Object.assign(this.options, options);
		},
		/**
		 * Hide loader (and show snackbar if enabled)
		 */
		hide() {
			this.dialog = false;
			if (this.snackbar.enabled) {
				this.showSnackbar();
			}
		},
		/**
		 * Start/Show Loader (and maybe Snackbar)
		 *
		 * @param {string} message
		 * @param {object} options
		 * @param {Promise.<function>} [callback]
		 * @param {boolean|object} [snackbar]
		 */
		start(message, options, callback, snackbar) {
			this.show(message, options);

			if (typeof snackbar === 'object') {
				this.snackbar.enabled = true;
				this.snackbar = Object.assign(this.snackbar, snackbar);
			} else if (typeof snackbar !== 'undefined') {
				this.snackbar.enabled = true;
			}

			if (typeof callback === 'function') { //in base alla Promise mostro la snackbar corretta
				callback()
                    .then(response => {//promise = resolve -> mostro il messaggio positivo
                        this.snackbar.message = response;
                        this.hide();
                    })
					.catch(error => {//promise = reject -> mostro il messaggio negativo
						this.snackbar.color = 'error';
						this.snackbar.message = error;
						this.hide();
					});
			}
		},
		/**
		 * Stop/Hide loader and show snackbar with optional callback
		 *
		 * @param {object} snackbarOptions
		 * @param {function} callback
		 */
		stop(snackbarOptions, callback) {
            if (typeof snackbarOptions === 'object') {
				this.snackbar.enabled = true;
				this.snackbar = Object.assign(this.snackbar, snackbarOptions);
			} else if (typeof snackbarOptions !== 'undefined') {
				this.snackbar.enabled = true;
			}

			if (typeof callback === 'function') { //in base alla Promise mostro la snackbar corretta
				callback()
                    .then(response => {//promise = resolve -> mostro il messaggio positivo
                        this.snackbar.message = response;
                        this.hide();
                    })
					.catch(error => {//promise = reject -> mostro il messaggio negativo
						this.snackbar.color = 'error';
						this.snackbar.message = error;
						this.hide();
					});
			}
		},
		/**
		 * Hide Snackbar (and call callback if previously set)
		 */
		hideSnackbar() {
			this.snackbar = false;
			this.snackbarVisible = false;
			if (this.snackbar.callback) {
				this.snackbar.callback();
			}
		},
		/**
		 * Show Snackbar
		 * @param {string} message
		 * @param {object} snackbarOptions
		 */
		showSnackbar(message, snackbarOptions) {
			if (message) {
				this.snackbar.message = message;
			}
			this.snackbar.enabled = true;

			if (typeof snackbarOptions === 'object') {
                //console.log(snackbarOptions)
                this.snackbar = Object.assign(this.snackbar, snackbarOptions);
                //console.log(this.snackbar)
			}

			this.snackbarVisible = true;
		}
	}
};
</script>
