<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn outlined rounded color="cariniClr" v-bind="attrs" v-on="on"><span class="hide-mobile">
          Scarica PDF </span><v-icon>mdi-download-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Inserisci i tuoi dati
					<v-spacer></v-spacer>
					<v-btn class="float-right" color="grey lighter-1" icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field label="Nome*" required v-model="name" :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Cognome*" required v-model="surname" :rules="[rules.required]"></v-text-field>
                </v-col> 
                
                <v-col cols="12">
                  <v-text-field label="Indirizzo" v-model="address"></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="8">
                  <v-text-field label="Località*" required v-model="city" :rules="[rules.required]" ></v-text-field>
                </v-col> 
                <v-col cols="12" sm="4">
                  <v-text-field label="CAP*" required v-model="cap" :rules="[rules.required, rules.numbers]" ></v-text-field>
                </v-col> 
                
                <v-col cols="12" sm="6">
                  <v-text-field label="Email*" required  v-model="email" :rules="[rules.required, rules.email]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Telefono" v-model="phone"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea auto-grow label="Note" v-model="note"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-checkbox v-model="contactme" label="Voglio essere contattato per ricevere un preventivo"></v-checkbox>
                </v-col>

              </v-row>
            </v-form>
          </v-container>
          <small>* campi obbligatori</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" outlined text @click="clear">Cancella</v-btn>
          <v-btn color="cariniClr" outlined @click="submit">Invia</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    data: () => ({
      valid: false,
      dialog: false,
      name: '',
      surname: '',
      address: '',
      city: '',
      cap: '',
      email: '',
      phone: '',
      note: '',
      contactme: true,
      rules: {
          required: value => !!value || 'Obbligatorio.',
          numbers: value => {
            const pattern = /^([0-9]*)$/
            return pattern.test(value) || 'Inserire soltanto numeri.'
          },
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'E-mail non valida.'
          },
        },
    }),
    methods: {
      clear() {
        this.$refs.form.reset()
      },
      submit() {
        if(this.$refs.form.validate()) {//se tutto ok
          //prendere i dati dal form e passarli all'app
          let formdata = {
            'name': this.name,
            'surname': this.surname,
            'address': this.address,
            'city': this.city,
            'cap': this.cap,
            'email': this.email,
            'phone': this.phone,
            'note': this.note,
            'contactme': this.contactme,
          }
          this.$emit('compiled', formdata)//aggiungere valori di ritorno
          this.$refs.form.reset()
          this.contactme = true
          this.dialog = false
        }
      }
    }
  }
</script>