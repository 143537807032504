<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent scrollable max-width="60%">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="primary" dark v-bind="attrs" v-on="on" >Scegli Modello</v-btn>
			</template>
			<v-card>
				<v-card-title class="headline">Scegli modello
					<v-spacer></v-spacer>
					<v-btn class="float-right" color="grey lighter-1" icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-form ref="form" v-model="valid" lazy-validation>
						<v-row>
							<v-radio-group v-model="keyOblo" row :rules="[rules.required]" >
								<v-col cols="3" v-for="(model, key) in modelli" :key="`${key}-model`">
									<v-radio :key="`${key}-radio`" :value="`${key}`" label="Seleziona"></v-radio>
									<img v-if="model.immagine" :src="model.immagine" :alt="model.Descrizione" width="100%">
									<!-- <img :src="`${publicPath}oblo.png`" :alt="model.label" width="100%"> -->
									<p class="text-center font-weight-bold" style="color:#333">{{model.Descrizione}}</p>
								</v-col>
							</v-radio-group>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					
					<v-spacer></v-spacer>
					<v-btn color="cariniClr darken-2" text @click="submit">Conferma</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
  export default {
	props: {
		modelli: {required:true},
		optKey: {required:true},
		optN: {required:true},
	},
    data: () => ({
		valid: false,
		dialog: false,
		keyOblo: '0',
		rules: {
			required: value => !!value || 'Obbligatorio.',
		},
    }),
	methods: {
		submit() {
			if(this.$refs.form.validate()) {//se tutto ok
				let formdata = {
					'modelloSel': this.modelli[this.keyOblo],
					'optKey': this.optKey,
					'optN': this.optN,
				}
				this.$emit('selectedOblo', formdata)
				//this.$refs.form.reset()
				this.dialog = false
			}
		}
	}
  }
</script>