<template>
	<v-container fluid>
		<v-row justify-content="space-between">
			<v-col cols="12" md="8">
				<v-card class="">
					<div
						class="svgContainer"
						v-if="currentStep == 5"
						v-html="renderSvg"
					></div>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12" md="6">
									<h2>{{ "Il mio portone" | uppercase }}</h2>
									<p class="mt5">
										Tipologia: {{ selTipologia ? selTipologia.name : null
										}}<br />
										Lunghezza (L): {{ width }}mm<br />
										Altezza (H): {{ height }}mm<br />
										<span v-if="left_space"
											>Spalletta Sinistra (SS): {{ left_space }}mm<br
										/></span>
										<span v-if="right_space"
											>Spalletta Destra (SD): {{ right_space }}mm<br
										/></span>
										<span v-if="architrave"
											>Architrave (A): {{ architrave }}mm<br
										/></span>
										<span v-if="depth"
											>Profondità (Z): {{ depth }}mm<br
										/></span>
									</p>
								</v-col>
								<v-col cols="12" md="6">
									<h2 v-if="selFinitura">{{ selFinitura.name | uppercase }}</h2>
									<p v-if="selFinitura">
										{{ selFinitura.desc }}
									</p>
									<h3 v-if="selColore">
										Colore:
										{{
											selColore.idClr != "-1"
												? selColore.name
												: selColore.name + selColore.ral
										}}
									</h3>
									<h3 v-if="selManto">Modello: {{ selManto.name }}</h3>

									<h3 v-if="selOptions.length > 0" class="mt-4">
										Opzioni Scelte
									</h3>
									<ul>
										<li v-for="(opt, key) in selOptions" :key="key">
											{{ opt.name }}
											<span v-if="opt.selWidth"
												><br />Larghezza:
												{{ opt.width[opt.selWidth].name }}</span
											>
											<span v-if="opt.selHeight"
												><br />Altezza:
												{{ opt.height[opt.selHeight].name }}</span
											>
											<span v-if="opt.selAlign"
												><br />Allineamento:
												{{ opt.align[opt.selAlign].name }}</span
											>
											<span v-if="opt.selHandle"
												><br />Maniglia:
												{{ opt.handle[opt.selHandle].name }}</span
											>
											<span v-if="opt.selModel"
												><br />Modello: {{ opt.selModel.Descrizione }}</span
											>
										</li>
									</ul>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="4">
				<v-card class="">
					<v-card-title>Opzioni</v-card-title>
					<v-card-subtitle>scegli le opzioni desiderate</v-card-subtitle>
					<v-card-text class="scroll">
						<template v-for="(opzione, n) in opzioni">
							<v-row :key="`${n}-opzione`" class="mt-1 mb-1">
								<v-col
									:cols="typeof opzione.modelli !== 'undefined' ? 8 : 12"
									class="my-0 py-0"
								>
									<h3>
										<v-checkbox
											v-model="opzione.sel"
											:label="opzione.name | uppercase"
											:id="`main-chk-` + opzione.key"
											:value="`main-chk-` + opzione.key"
											@change="updateOptions(opzione.sel, opzione.key)"
										></v-checkbox>
									</h3>
									<p class="mt-5" v-if="opzione.desc">{{ opzione.desc }}</p>
								</v-col>
								<v-col cols="4" v-if="typeof opzione.modelli !== 'undefined'">
									<DialogOblo
										:modelli="opzione.modelli"
										:optN="n"
										:optKey="opzione.key"
										@selectedOblo="updateModel"
									></DialogOblo>
								</v-col>

								<!-- porta pedonale -->
								<v-col
									cols="12"
									v-if="opzione.width && opzione.sel"
									class="my-0 py-0"
								>
									<v-row class="my-0 py-0">
										<v-col class="my-0 py-0" cols="4"
											><h4>Larghezza:</h4></v-col
										>
										<v-col class="my-0 py-0" cols="8">
											<v-radio-group v-model="doorWidth" row class="my-0 py-0">
												<template v-for="(width, n) in opzione.width">
													<v-radio
														:key="`${n}-radio`"
														:value="`${n}`"
														:label="width.name"
														@change="updateDoor('width', n)"
													></v-radio>
												</template>
											</v-radio-group>
										</v-col>
									</v-row>
								</v-col>
								<v-col
									cols="12"
									v-if="opzione.align && opzione.sel"
									class="my-0 py-0"
								>
									<v-row class="my-0 py-0">
										<v-col class="my-0 py-0" cols="4"
											><h4>Allineamento:</h4></v-col
										>
										<v-col class="my-0 py-0" cols="8">
											<v-radio-group v-model="doorAlign" row class="my-0 py-0">
												<template v-for="(align, n) in opzione.align">
													<v-radio
														:key="`${n}-radio`"
														:value="`${n}`"
														:label="align.name"
														@change="updateDoor('align', n)"
													></v-radio>
												</template>
											</v-radio-group>
										</v-col>
									</v-row>
								</v-col>
								<v-col
									cols="12"
									v-if="opzione.handle && opzione.sel"
									class="my-0 py-0"
								>
									<v-row class="my-0 py-0">
										<v-col class="my-0 py-0" cols="4"><h4>Maniglia:</h4></v-col>
										<v-col class="my-0 py-0" cols="8">
											<v-radio-group v-model="doorHandle" row class="my-0 py-0">
												<template v-for="(handle, n) in opzione.handle">
													<v-radio
														:key="`${n}-radio`"
														:value="`${n}`"
														:label="handle.name"
														@change="updateDoor('handle', n)"
													></v-radio>
												</template>
											</v-radio-group>
										</v-col>
									</v-row>
								</v-col>

								<!-- oblò, griglie -->
								<v-col
									cols="12"
									v-if="typeof opzione.modelli !== 'undefined'"
									class="mx-2 my-0 py-0"
								>
									<v-row
										v-for="(rrow, nn) in opzione.rows"
										:key="`${nn}-rrow`"
										:id="`${nn}-ctrl-` + opzione.key"
										class="my-0 py-0 rrow"
										:class="rrow.max == 0 ? 'disable' : ''"
									>
										<v-col class="my-0 py-0" cols="5">
											<v-checkbox
												v-if="opzione.key !== 'sezioneVetrata'"
												v-model="chkRRows"
												:value="`${nn}-chk-` + opzione.key"
												:label="`${nn + 1}° sezione`"
												@change="disableRelated(n, nn, opzione.key)"
											></v-checkbox>
										</v-col>
										<v-col
											v-if="opzione.key !== 'sezioneVetrata'"
											class="my-0 py-0"
											cols="7"
										>
											<v-slider
												v-model="distRows[`${n}-${nn}`]"
												class="align-center"
												:min="rrow.min"
												:max="rrow.max"
												:step="rrow.step"
												hide-details
												@change="updNumOblo(n, nn, opzione.key)"
											>
												<template v-slot:append>
													<v-text-field
														v-model="distRows[`${n}-${nn}`]"
														class="mt-0 pt-0"
														hide-details
														single-line
														type="number"
														:min="rrow.min"
														:max="rrow.max"
														:step="rrow.step"
														style="width: 60px"
														@input="updNumOblo(n, nn, opzione.key)"
													></v-text-field>
												</template>
											</v-slider>
										</v-col>
									</v-row>
								</v-col>
								<!-- Fine oblò, sezioni vetrate e griglie -->
								<!-- Sezione Vetrata -->
								<v-col
									cols="12"
									v-if="opzione.height && opzione.sel"
									class="my-0 py-0"
								>
									<v-row class="my-0 py-0">
										<v-col class="my-0 py-0" cols="4"><h4>Altezza:</h4></v-col>
										<v-col class="my-0 py-0" cols="8">
											<v-radio-group
												v-model="sezVetrHeight"
												row
												class="my-0 py-0"
											>
												<template v-for="(height, n) in opzione.height">
													<v-radio
														:key="`${n}-radio`"
														:value="`${n}`"
														:label="height.name"
														@change="updateSezioneVetrataHeight('height', n)"
													></v-radio>
												</template>
											</v-radio-group>
										</v-col>
									</v-row>
								</v-col>
								<!-- FINE sezione vetrata -->
							</v-row>
							<v-divider :key="`${n}-sep`"></v-divider>
						</template>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import DialogOblo from "./DialogOblo";
import { mapState } from "vuex";

export default {
	components: {
		DialogOblo,
	},
	props: {},
	data: () => ({
		doorWidth: "0",
		doorAlign: "1",
		doorHandle: "0",
		sezVetrHeight: "0",
		chkRRows: [],
		distRows: [],
	}),
	computed: {
		...mapState([
			"currentStep",
			"renderSvg",
			"width",
			"height",
			"left_space",
			"right_space",
			"architrave",
			"depth",
			"selManto",
			"selFloor",
			"selWall",
			"selFinitura",
			"selColore",
			"opzioni",
			"selOptions",
			"selTipologia",
		]),
	},
	methods: {
		async updateOptGrid(key, oblow, obloh) {
			//aggiorno le opzioni possibili sul modello
			let door = this.selOptions.filter((opt) => opt.key == "door")[0];
			let sezVetrata = this.selOptions.filter((opt) => opt.key == "sezioneVetrata")[0];
			let sezVetr = 0;
			if(sezVetrata){
				if (sezVetrata.selHeight == "3" || sezVetrata.selHeight == "4"){
					sezVetr = 110;
				}
			}
			// let sezioneVetrata = this.selOptions.filter(
			// 	(opt) => opt.key == "sezioneVetrata"
			// )[0];
			let getOpzRows = await window.axios.get(
				this.apiRoot + "opzioni/getRows",
				{
					params: {
						disegno: this.selManto.disegno,
						w: this.width,
						ow: oblow,
						oh: obloh,
						ppw:
							typeof door !== "undefined"
								? door.width[door.selWidth].val
								: null,
						ppa:
							typeof door !== "undefined"
								? door.align[door.selAlign].code
								: null,
						l: this.left_space,
						r: this.right_space,
						a: this.architrave,
						d: this.depth,
						sv: sezVetr,
						// svh:
						// 	typeof sezioneVetrata !== "undefined"
						// 		? sezioneVetrata.height[sezioneVetrata.selHeight].val
						// 		: null,
						// tipologia: this.selTipologia.idType,
					},
					paramsSerializer: (params) => {
						return window.qs.stringify(params);
					},
				}
			);
			if (key == "oblo") this.$store.commit("updateObloRows", getOpzRows.data);
			else if (key == "griglia")
				this.$store.commit("updateGrigliaRows", getOpzRows.data);
			else if (key == "sezioneVetrata")
				this.$store.commit("updateSezVetrataRows", 1);
			return true;
		},
		async updateAllOptGridAndRender() {
			let promise1 = null;
			let promise2 = null;
			let promise3 = null;

			//update rows x oblo
			let oblo = this.opzioni.filter((opt) => opt.key == "oblo")[0];
			if (oblo !== undefined && oblo.selModel) {
				promise1 = await this.updateOptGrid(
					"oblo",
					oblo.selModel.Larghezza,
					oblo.selModel.Altezza
				);
			}
			//update rows x griglie
			let griglia = this.opzioni.filter((opt) => opt.key == "griglia")[0];
			if (griglia !== undefined && griglia.selModel) {
				promise2 = await this.updateOptGrid(
					"griglia",
					griglia.selModel.Larghezza,
					griglia.selModel.Altezza
				);
			}

			promise3 = await this.updateDistribution();

			if (promise1 && promise2 && promise3) {
				//render
				this.$emit("updateSvg");
				return true;
			} else {
				return false;
			}
		},
		async updateOptions(sel, key) {
			//salva le opzioni scelte
			let checked = this.opzioni.filter((opt) => opt.sel);
			this.$store.commit("updateSelOptions", checked);
			//console.log(sel + " - " + key)
			//console.log(this.selOptions)

			if (key == "door") {
				this.$store.commit("updateDoorWidth", this.doorWidth);
				this.$store.commit("updateDoorAlign", this.doorAlign);
				this.$store.commit("updateDoorHandle", this.doorHandle);
				//disegnoManto
				let idDisegnoManto = sel
					? this.selManto.idDisConPorta
					: this.selManto.idDisSenzaPorta;
				let getDisegnoManto = await window.axios.get(
					this.apiRoot + "manti/disegno/" + idDisegnoManto
				);
				this.$store.commit("updateDisegnoManto", getDisegnoManto.data);
				this.updateAllOptGridAndRender();
			}

			if (key == "oblo" || key == "griglia") {
				this.updateDistribution();
				this.$emit("updateSvg");
			}

			if (key == "sezioneVetrata") {
				this.$store.commit("updateSezioneVetrataHeight", this.sezVetrHeight);
				this.updateAllOptGridAndRender();
				this.disableCheckOnVetrata(key);
			}
		},
		updateDoor(type, key) {
			//console.log(type + " - " + key)
			this.selOptions.forEach((opt) => {
				if (opt.key == "door") {
					if (type == "width") {
						this.$store.commit("updateDoorWidth", String(key));
					}
					if (type == "align") {
						this.$store.commit("updateDoorAlign", String(key));
					}
					if (type == "handle") {
						this.$store.commit("updateDoorHandle", String(key));
					}
					this.updateAllOptGridAndRender();
				}
			});
		},
		updateSezioneVetrataHeight(type, key) {
			this.selOptions.forEach((opt) => {
				if (opt.key == "sezioneVetrata") {
					if (type == "height") {
						this.$store.commit("updateSezioneVetrataHeight", String(key));
					}
					this.updateAllOptGridAndRender();
				}
			});
		},
		updateModel(selectedOblo) {
			//scelto modello dalla modale
			//console.log(selectedOblo)
			this.opzioni[selectedOblo.optN].sel = true; //metti selezionata l'opzione relativa
			let optKey = selectedOblo.optKey;
			this.updateOptions(true, selectedOblo.optKey);
			if (optKey == "oblo") {
				this.$store.commit("updateObloModel", selectedOblo.modelloSel);
			} else if (optKey == "griglia") {
				this.$store.commit("updateGrigliaModel", selectedOblo.modelloSel);
			} else if (optKey == "sezioneVetrata") {
				this.$store.commit(
					"updateSezioneVetrataModel",
					selectedOblo.modelloSel
				);
			}
			this.updateAllOptGridAndRender();
		},
		async disableRelated(n, nn, key) {
			//simula un radio
			// console.log(n + " - " + nn + " - " + key);
			// console.log(this.chkRRows)
			// console.log(this.distRows)

			var clicked = nn + "-chk-" + key;
			var disableKey = key == "oblo" ? "griglia" : "oblo";
			var other = nn + "-ctrl-" + disableKey;
			// let num = this.distRows[n + "-" + nn];
			if (this.chkRRows.includes(clicked)) {
				//se mi hai selezionato disabilito l'altro
				document.getElementById(other).classList.add("disable");
			} else {
				//se deselezionato riabilito l'altro
				document.getElementById(other).classList.remove("disable");
				// if (num > 0) {
				// 	await new Promise(r => setTimeout(r, 2000))
				// 	this.$emit("updateSvg"); //se era impostato il numero di oblo allora aggiorno il rendering
				// }
			}
			this.updateDistribution(n, nn, key);
		},
		async disableCheckOnVetrata(key) {
			var sezVetrata = document.getElementById('main-chk-'+key);
			var oblo = "0-ctrl-oblo";
			var griglia = "0-ctrl-griglia";
			if (!sezVetrata.checked) {
				//se selezionato vetrata disabilito oblo e griglia
				document.getElementById(oblo).classList.add("disable");
				document.getElementById(griglia).classList.add("disable");
			} else {
				//se deselezionato vetrata abilito oblo e griglia
				document.getElementById(oblo).classList.remove("disable");
				document.getElementById(griglia).classList.remove("disable");
			}
		},
		drawSezioneVetrata(n, nn, key) {
			this.disableRelated(n, nn, key);
			this.updateSezVetrata(n, 1, key);
		},
		updNumOblo(n, nn, key) {
			// console.log(n + " - " + nn + " - " + key);
			// console.log(this.distRows[n + "-" + nn]);
			this.updateDistribution(n, nn, key);
		},
		async updateSezVetrata() {
			let distrSezVetrata = [];
			distrSezVetrata.push({ sez: 1, num: 1, align: "C" });
			let sezioneVetrata = this.selOptions.filter(
				(opt) => opt.key == "sezioneVetrata"
			)[0];
			if (typeof sezioneVetrata !== "undefined") {
				this.$store.commit("updateSezVetrataDistrModel", distrSezVetrata);
			}
			this.$emit("updateSvg");
			return true;
		},
		async updateDistribution(n, nn, key) {
			//aggiornare la distribuzione degli oblo e delle griglie in base alle cose selezionate nel relativo selOptions
			// console.log("upd-distr");
			let distrOblo = [];
			let distrGriglia = [];
			let distrSezVetrata = [];
			for (let i in this.distRows) {
				//i=n-nn
				let [n, nn] = i.split("-");
				let key = this.opzioni[n].key;
				let num = this.distRows[i];
				if (
					key == "oblo" &&
					num > 0 &&
					this.chkRRows.includes(nn + "-chk-" + key)
				)
					distrOblo.push({ sez: nn, num: num, align: "C" }); //TO DO: inserire la selezione per l'allineamento e gestirla interamente
				if (
					key == "griglia" &&
					num > 0 &&
					this.chkRRows.includes(nn + "-chk-" + key)
				)
					distrGriglia.push({ sez: nn, num: num, align: "C" }); //TO DO: inserire la selezione per l'allineamento e gestirla interamente
				if (key == "sezioneVetrata")
					distrSezVetrata.push({ sez: 1, num: 1, align: "C" }); //TO DO: inserire la selezione per l'allineamento e gestirla interamente
				//if(num>0)
				//console.log(i + " = " + key + " - " + nn + " : " + num);
			}
			// console.log(distrOblo)
			// console.log(distrGriglia)
			// console.log(distrSezVetrata);
			let oblo = this.selOptions.filter((opt) => opt.key == "oblo")[0];
			if (typeof oblo !== "undefined")
				this.$store.commit("updateObDistrModel", distrOblo);

			let griglia = this.selOptions.filter((opt) => opt.key == "griglia")[0];
			if (typeof griglia !== "undefined")
				this.$store.commit("updateGrDistrModel", distrGriglia);

			//effettua il render se è stato cliccato un checkbox con slider settato o viceversa, se la propria opzione (oblo/griglia) è attivata
			if (
				typeof n !== "undefined" &&
				typeof nn !== "undefined" &&
				typeof key !== "undefined"
			) {
				let option = this.selOptions.filter((opt) => opt.key == key)[0];
				let num = this.distRows[n + "-" + nn]; //numero oblo/griglia corrente
				if (
					typeof option !== "undefined" &&
					num > 0 &&
					this.chkRRows.includes(nn + "-chk-" + key)
				)
					this.$emit("updateSvg");
				if (
					typeof option !== "undefined" &&
					num == 0 &&
					this.chkRRows.includes(nn + "-chk-" + key)
				)
					this.$emit("updateSvg");
				if (
					typeof option !== "undefined" &&
					!this.chkRRows.includes(nn + "-chk-" + key)
				)
					this.$emit("updateSvg");
			}
			return true;
		},
	},
};
</script>
<style>
.rrow .v-input--selection-controls,
.rrow .v-input__append-outer,
.rrow .v-input__slot {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.v-input--selection-controls__ripple:before {
	z-index: 2;
}
.disable {
	position: relative;
}
.disable:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: white;
	opacity: 0.8;
	color: white;
	z-index: 1;
}
.disable .v-input--selection-controls__ripple {
	display: none;
}
</style>
