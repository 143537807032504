<template>
	<v-container fluid class="">
		<v-row>
			<v-col cols="12" md="8">
				<v-card class="" id="innerView">
					<img
						:src="`${publicPath}rilievo-misure.svg`"
						alt="schema misure"
						width="100%"
					/>
				</v-card>
			</v-col>

			<v-col cols="12" md="4">
				<v-card class="">
					<v-card-title>Tipologia Basculante</v-card-title>
					<v-card-subtitle
						>scegli una tra le due tipologie di basculante</v-card-subtitle
					>
					<v-card-text>
						<v-radio-group v-model="idTipologia">
							<template v-for="(tipologia, n) in tipologie">
								<v-row :key="`${n}-tipologia`">
									<v-col cols="9">
										<h3 class="mt-2 mb-2">
											<v-radio
												:value="`${n}`"
												:label="tipologia.name | uppercase"
												@change="changeTipologia(tipologia.idTipologia, n)"
											></v-radio>
										</h3>
										<p class="mt-5" v-if="tipologia.desc">
											{{ tipologia.desc }}
										</p>
									</v-col>
									<v-col cols="3">
										
										<img :src="`${publicPath+tipologia.nameFile}`" :alt="tipologia.name" width="100%">
									</v-col>
								</v-row>
								<v-divider :key="`${n}-sep`"></v-divider>
							</template>
						</v-radio-group>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
	props: {},
	data: () => ({
		valid: false,
		radios: null,
		idTipologia: "0",
	}),
	computed: {
		tipologia: {
			get() {
				return this.$store.state.tipologia;
			},
			set(value) {
				this.$store.commit("updateTipologia", value);
			},
		},
		...mapState([
			"currentStep",
			"renderSvg",
			"width",
			"height",
			"left_space",
			"right_space",
			"architrave",
			"depth",
			"tipologie",
			"tipologia",
		]),
	},

	methods: {
		async changeTipologia(idType, n) {
			this.$store.commit("updateTipologia", this.$store.state.tipologie[n]);
			this.$emit("stepIsValid", true);
		},
		waitasec() {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.submit();
			}, 1000);
		},
		async submit() {
			this.$emit("waitingBtn", true);
		},
	},
	created() {
		this.$emit("stepIsValid", true);
		window.axios.get(this.apiRoot + "tipologie").then((response) => {
			this.$store.commit("updateTipologie", response.data)
			this.$store.commit("updateTipologia", response.data[0])
		});
		window.axios.get(this.apiRoot+'typesImg')
		.then(response => {
				this.$store.commit('updateTypesImg', response.data)
		})
	},
};
</script>
<style scoped>
#innerView {
	position: relative;
}
#innerView div {
	position: absolute;
	width: 4%;
	font-size: 1.2em;
	white-space: nowrap;
	font-weight: 600;
}
#showWidth {
	top: 64.5%;
	left: 49.5%;
}
#showHeight {
	top: 46%;
	left: 61%;
}
#showLeftSpace {
	top: 51%;
	left: 26.2%;
}
#showArchitrave {
	top: 23.5%;
	left: 50%;
}
#showRightSpace {
	top: 51%;
	left: 69.5%;
}
#showDepth {
	top: 75.5%;
	left: 85%;
}
@media screen and (max-width: 1349px) {
	#innerView div {
		font-size: 1em;
	}
}
@media screen and (max-width: 959px) {
	#innerView div {
		font-size: 1.2em;
	}
}
@media screen and (max-width: 779px) {
	#innerView div {
		font-size: 1em;
	}
}
@media screen and (max-width: 549px) {
	#showWidth {
		top: 63%;
	}
	#showArchitrave {
		top: 21%;
	}
	#showDepth {
		top: 74%;
	}
}
</style>
