<template>
		<v-container fluid>
		<v-row justify-content="space-between">
				<v-col cols="12" md="8">

						<v-card class="" >
								<div class="svgContainer" v-if="currentStep==4" v-html="renderSvg"></div>
								<v-card-text>
										<v-container>
												<v-row>
														<v-col cols="12" md="6">
																<h2>{{"Il mio portone" | uppercase}}</h2>
																<p class="mt5">
																		Tipologia: {{selTipologia ? selTipologia.name : null}}<br>
																		Lunghezza (L): {{width}}mm<br>
																		Altezza (H): {{height}}mm<br>
																		<span v-if="left_space">Spalletta Sinistra (SS): {{left_space}}mm<br></span>
																		<span v-if="right_space">Spalletta Destra (SD): {{right_space}}mm<br></span>
																		<span v-if="architrave">Architrave (A): {{architrave}}mm<br></span>
																		<span v-if="depth">Profondità (Z): {{depth}}mm<br></span>
																</p>
														</v-col>
														<v-col cols="12" md="6">
																<h2 v-if="selFinitura">{{selFinitura.name | uppercase}}</h2>
																<p v-if="selFinitura">
																		{{selFinitura.desc}}
																</p>
																<h3 v-if="selColore">Colore: {{selColore.idClr!='-1' ? selColore.name : selColore.name+selColore.ral }}</h3>
																<h3 v-if="selManto">Modello: {{selManto.name}}</h3>
														</v-col>
												</v-row>
										</v-container>
								</v-card-text>
						</v-card>

				</v-col>
				<v-col cols="12" md="4">

						<v-card class="" >
								<v-card-title>Colori</v-card-title>
								<v-card-text class="scroll">
										<v-radio-group v-model="idColor" >
												<template v-for="(val, key) in colori"> 
														<v-row :key="`${key}-col`">
																<v-col cols="7">
																		<h3><v-radio :value="`${key}`" :label="val.name | uppercase" @change="changeSelCrl(key, val.idClr)"></v-radio></h3>
																</v-col>
																<v-col cols="5">
																		<div v-if="val.idClr=='-1'">
																				<v-text-field v-model="ralClr" label="RAL" maxlength="4" @input="changeHex" :rules="[rules.exact]"></v-text-field>
																		</div>
																		<img v-else-if="val.coloreIMG" :src="val.coloreIMG" :alt="val.name" width="60px" height="30px" style="float:right">
																		<div v-else :style="`background: #${val.hex}; width: 60px; height: 30px; float: right; border: 1px solid #ccc`"></div>
																</v-col>
																<div v-if="val.idClr=='-1'" class="ralColors">
																		<v-color-picker @input="changeRal" v-model="hexClr" :swatches="swatches" swatches-max-height="300px"
																						show-swatches disabled hide-inputs hide-canvas hide-mode-switch></v-color-picker>
																</div>
														</v-row>
														<v-divider :key="`${key}-sep`"></v-divider>
												</template>
										</v-radio-group>
								</v-card-text>
						</v-card>

				</v-col>
		</v-row>
</v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
		props: { },
		data: () => ({
				hexClr: '',
				ralClr: '',
				rules: {
						//inTable: value => (value!='') || 'RAL non presente in tabella',
						exact: value => (value && value.length == 4) || '4 caratteri',
				}
		}),
		computed: {
				idColor: {
						get() { return this.$store.state.idColor },
						set (value) {  this.$store.commit('updateIdColor', value) }
				},
				...mapState(['currentStep', 'renderSvg', 'selTipologia', 'width', 'height', 'left_space', 'right_space', 'architrave', 'depth', 'selManto', 'selFloor', 'selWall', 'selFinitura', 'colori', 'swatches', 'ralTable', 'selColore', 'oldRal', 'oldHex', 'selTipologia']),
		},
		methods: {
				async selAutoManto(idColoreScelto, method) {
						let getManto = await window.axios.get(this.apiRoot+'manti/'+method+'/'+idColoreScelto+'?h='+this.height+'&idType='+this.selFinitura.idType+'&'+this.$store.state.urlParams)
						this.$store.commit('updateManto', getManto.data)
						//opzioni
						window.axios.get(this.apiRoot+'opzioni', {
								params: {
										tipologia: this.$store.state.selTipologia,
										quadrettato: (this.selFinitura.BugnaH>0 || this.selFinitura.BugnaL>0)?1:0,
										manto: this.selManto, 
										w: this.width,
										l: this.left_space,
										r: this.right_space,
										a: this.architrave,
										d: this.depth,
								},
								paramsSerializer: params => {
										return window.qs.stringify(params)
								}}
						).then(response => {
								this.$store.commit('updateOpzioni', response.data)
						})
						this.$store.commit('updateSelOptions', [])//reset se torni indietro dallo step 4

				},
				changeSelCrl(idCol, selCol) {
						// console.log("["+idCol + "] " + selCol)
						if(selCol!=-1) {
								this.$store.commit('updateColore', this.colori[idCol])
								this.selAutoManto(selCol, 'byclr')
						} else {
								// console.log(this.ralClr)
								if(typeof this.ralClr == 'undefined') {
										this.hexClr = this.swatches[0][0]
										this.ralClr = this.getKeyByValue(this.ralTable, this.hexClr)
								}
								this.$store.commit('updateCustomColore', {'hex': this.hexClr, 'ral': this.ralClr})
								this.selAutoManto(this.ralClr, 'byral')
						}
						this.$emit('updateSvg')
				},
				changeHex() {
						if (this.ralClr.length == 4) {
								// console.log(this.ralClr + " / " + this.hexClr)
								let selClr = this.ralTable[this.ralClr]
								// console.log(selClr)
								if(typeof selClr != 'undefined') {
										console.log(this.selColore.idClr)
										this.hexClr = this.ralTable[this.ralClr]
										this.$store.commit('updateOldRal', this.ralClr)
										if(this.selColore.idClr=='-1') { //se è checkato CUSTOM
												this.$store.commit('updateCustomColore', {'hex': this.hexClr, 'ral': this.ralClr})
												this.selAutoManto(this.ralClr, 'byral')
												this.$emit('updateSvg')
										}
								} else {
										alert('Il colore RAL \''+this.ralClr+'\' è inesistente');
										this.ralClr = this.oldRal
								}
						}
				},
				changeRal() {
						this.ralClr = this.getKeyByValue(this.ralTable, this.hexClr);
						this.$store.commit('updateOldRal', this.ralClr)
						// console.log(this.selColore)
						if(this.selColore !== null && typeof this.hexClr != 'undefined' && typeof this.ralClr != 'undefined') {//se è checkato CUSTOM
								if(this.selColore.idClr=='-1') {
										this.$store.commit('updateCustomColore', {'hex': this.hexClr, 'ral': this.ralClr})
										this.selAutoManto(this.ralClr, 'byral')
										this.$emit('updateSvg')
								}
						}
				},
				getKeyByValue(object, value) {
						return Object.keys(object).find(key => object[key] === value);
				},
		}
}
</script>
<style>
.ralColors { display: block; width: 100%; }
.v-color-picker { max-width: 100% !important; }
.v-color-picker__controls { display: none !important; }
.v-color-picker__controls { display: none !important; }

.v-color-picker__swatches > div { display: block !important; }
.v-color-picker__swatch { display: block !important; }
.v-color-picker__color { 
		display: inline-block !important; 
		border: 1px solid #777; 
		height: 20px !important; 
		max-height: 20px !important; 
		width: 42px !important;
		margin: 0 2px !important;
}
</style>