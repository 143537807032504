<template>
	<v-container fluid class="">
		<v-row>
			<v-col cols="12" md="8">
				<v-card class="" id="innerView">
					<!-- <div class="svgContainer" v-if="currentStep==2" v-html="renderSvg"></div> -->
					<img
						:src="`${publicPath}rilievo-misure.svg`"
						alt="schema misure"
						width="100%"
					/>
					<div align="center" id="showWidth">{{ width }}</div>
					<div align="center" id="showHeight">{{ height }}</div>
					<div align="center" id="showLeftSpace">{{ left_space }}</div>
					<div align="center" id="showArchitrave">{{ architrave }}</div>
					<div align="center" id="showRightSpace">{{ right_space }}</div>
					<div align="center" id="showDepth">{{ depth }}</div>
				</v-card>
			</v-col>

			<v-col cols="12" md="4">
				<v-card class="">
					<v-card-title>Dimensioni</v-card-title>
					<v-card-subtitle
						>inserisci le misure secondo lo schema qui a fianco</v-card-subtitle
					>
					<v-card-text>
						<v-form ref="form" v-model="valid" lazy-validation>
							<v-row>
								<v-col cols="12" sm="6">
									<v-text-field
										label="Larghezza (L)"
										suffix="mm"
										v-model="width"
										:rules="[rules.required, rules.min, rules.max]"
										@input="waitasec"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field
										label="Altezza (H)"
										suffix="mm"
										v-model="height"
										:rules="[rules.required, rules.min, rules.max]"
										@input="waitasec"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6">
									<v-text-field
										label="Spalla sinistra (SS)"
										suffix="mm"
										v-model="left_space"
										:rules="[rules.max]"
										@input="waitasec"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field
										label="Spalla destra (SD)"
										suffix="mm"
										v-model="right_space"
										:rules="[rules.max]"
										@input="waitasec"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6">
									<v-text-field
										label="Architrave (A)"
										suffix="mm"
										v-model="architrave"
										:rules="[rules.max]"
										@input="waitasec"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field
										label="Profondità (Z)"
										suffix="mm"
										v-model="depth"
										:rules="[rules.max]"
									></v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-alert
			v-if="oversize"
			v-html="oversize"
			type="error"
			text
			outlined
			color="red"
			border="right"
			transition="scale-transition"
		></v-alert>
	</v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
	props: {},
	data: () => ({
		oversize: false,
		valid: false,
		// urlParams: null,
		rules: {
			required: (value) => !!value || "Obbligatorio.",
			min: (value) => {
				const pattern = /^[0-9]{4,}$/;
				return pattern.test(value) || "Minimo 1000 mm.";
			},
			max: (value) => {
				const max = 47000;
				return max >= value || "Massimo 47000 mm.";
			},
		},
	}),
	computed: {
		width: {
			get() {
				return this.$store.state.width;
			},
			set(value) {
				this.$store.commit("updateWidth", value);
			},
		},
		height: {
			get() {
				return this.$store.state.height;
			},
			set(value) {
				this.$store.commit("updateHeight", value);
			},
		},
		left_space: {
			get() {
				return this.$store.state.left_space;
			},
			set(value) {
				this.$store.commit("updateLeftSpace", value);
			},
		},
		architrave: {
			get() {
				return this.$store.state.architrave;
			},
			set(value) {
				this.$store.commit("updateArchitrave", value);
			},
		},
		right_space: {
			get() {
				return this.$store.state.right_space;
			},
			set(value) {
				this.$store.commit("updateRightSpace", value);
			},
		},
		depth: {
			get() {
				return this.$store.state.depth;
			},
			set(value) {
				this.$store.commit("updateDepth", value);
			},
		},
		id: {
			get() {
				return this.$store.state.selTipologia.idType;
			},
		},
		...mapState([
			"currentStep",
			"renderSvg",
			// "finiture",
			"selTipologia",
		]),
	},
	
	methods: {
		waitasec() {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.submit();
			}, 1000);
		},
		async submit() {
			this.$store.commit("updateWidth", this.width);
			this.$store.commit("updateHeight", this.height);
			this.$store.commit("updateLeftSpace", this.left_space);
			this.$store.commit("updateRightSpace", this.right_space);
			// this.$store.commit('updateDepth', this.depth)
			this.$store.commit("updateArchitrave", this.architrave);
			this.$emit("waitingBtn", true);
			if (this.$refs.form.validate()) {	//se tutto ok
				this.oversize = false;
				this.$emit("stepIsValid", true);
				this.$emit("waitingBtn", false);

				//filtro per dimensioni inserite
				let mantiResp = await window.axios.get(
					this.apiRoot + "manti?w=" + this.width + "&h=" + this.height + "&idSerramento=" + this.id
				);
				
				if (mantiResp.data.status === false ) { // creo l'alert dimesioni fuori standard
					this.$emit("stepIsValid", false);
					this.$root.$dialogLoader.showSnackbar(
						mantiResp.data.message,
						new Object({ color: "orange darken-4", timeout: 10000 })
					);
				} else {
					this.$store.commit("updateManti", mantiResp.data);
					this.$store.commit("updateManto", mantiResp.data[0]);

					let FinColsResp = await window.axios.get(
						this.apiRoot + "finiture?" + this.$store.state.urlParams + "&idTipologia=" + this.$store.state.selTipologia.idType
					);
					if (FinColsResp.data.status === false) {
						this.$emit("stepIsValid", false);
						this.$root.$dialogLoader.showSnackbar(
							FinColsResp.data.message,
							new Object({ color: "orange darken-4", timeout: 10000 })
						);
					} else {
						this.$store.commit("updateFiniture", FinColsResp.data);
						this.$store.commit("updateFinitura", FinColsResp.data[0]);
						
						//colori
						window.axios
							.get(
								this.apiRoot +
									"colori?idType=" +
									this.$store.state.selFinitura.idType +
									"&" +
									this.$store.state.urlParams
							)
							.then((response) => {
								this.$store.commit("updateColori", response.data.colori);
								this.$store.commit("updateColore", response.data.colori[0]);

								//scelgo manto/finitura in base ai dati ricevuti finora
								window.axios
									.get(
										this.apiRoot +
											"manti/byclr/" +
											this.$store.state.selColore.idClr +
											"?h=" +
											this.height +
											"&idType=" +
											this.$store.state.selFinitura.idType +
											"&" +
											this.$store.state.urlParams
									)
									.then((response) => {
										this.$store.commit("updateManto", response.data);
									});

								if (typeof response.data.fuoriStd !== "undefined")
									this.$store.commit("updateFuoriStd", response.data.fuoriStd);
								else	this.$store.commit("updateFuoriStd", null);
							});

						//opzioni
						window.axios
							.get(this.apiRoot + "opzioni", {
								params: {
									tipologia: this.$store.state.selTipologia,
									quadrettato: (this.$store.state.selFinitura.BugnaH > 0 ||	this.$store.state.selFinitura.BugnaL > 0)	? 1	: 0,
									manto: this.$store.state.selManto,
									w: this.width,
									l: this.left_space,
									r: this.right_space,
									a: this.architrave,
								},
								paramsSerializer: (params) => {
									return window.qs.stringify(params);
								},
							})
							.then((response) => {
								this.$store.commit("updateOpzioni", response.data);
							});

						this.$store.commit("updateSelOptions", []); //reset se torni indietro dallo step 4
						this.$store.commit("updateIdModel", "0"); //resetto per step successivo
						// this.$emit("updateSvg", true);
						this.$emit("stepIsValid", true);
					}
				}
			} else {
				this.$emit("stepIsValid", false);
			}
			this.$emit("waitingBtn", false);
		},
		
		depthRules(val) {
			if (val < this.height) {
				return "La profondità deve essere maggiore di " + this.height + " mm";
			} else {
				return true;
			}
		},

	},
};
</script>
<style scoped>
#innerView {
	position: relative;
}
#innerView div {
	position: absolute;
	width: 4%;
	font-size: 1.2em;
	white-space: nowrap;
	font-weight: 600;
}
#showWidth {
	top: 64.5%;
	left: 49.5%;
}
#showHeight {
	top: 46%;
	left: 61%;
}
#showLeftSpace {
	top: 51%;
	left: 26.2%;
}
#showArchitrave {
	top: 23.5%;
	left: 50%;
}
#showRightSpace {
	top: 51%;
	left: 69.5%;
}
#showDepth {
	top: 75.5%;
	left: 85%;
}
@media screen and (max-width: 1349px) {
	#innerView div {
		font-size: 1em;
	}
}
@media screen and (max-width: 959px) {
	#innerView div {
		font-size: 1.2em;
	}
}
@media screen and (max-width: 779px) {
	#innerView div {
		font-size: 1em;
	}
}
@media screen and (max-width: 549px) {
	#showWidth {
		top: 63%;
	}
	#showArchitrave {
		top: 21%;
	}
	#showDepth {
		top: 74%;
	}
}
</style>
