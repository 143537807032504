<template>
		<v-container fluid>
		<v-row justify-content="space-between">
				<v-col cols="12">

						<v-card class="" >
								<div class="svgContainer" v-if="currentStep==6" v-html="renderSvg"></div>
								<v-card-text>
										<v-container>
												<v-row>
														<v-col cols="12" md="6">
																<h2>{{"Il mio portone" | uppercase}}</h2>
																<p class="mt5">
																		Tipologia: {{selTipologia ? selTipologia.name : null}}<br>
																		Lunghezza (L): {{width}}mm<br>
																		Altezza (H): {{height}}mm<br>
																		<span v-if="left_space">Spalletta Sinistra (SS): {{left_space}}mm<br></span>
																		<span v-if="right_space">Spalletta Destra (SD): {{right_space}}mm<br></span>
																		<span v-if="architrave">Architrave (A): {{architrave}}mm<br></span>
																		<span v-if="depth">Profondità (Z): {{depth}}mm<br></span>
																</p>
														</v-col>
														<v-col cols="12" md="6">
																<h2 v-if="selFinitura">{{selFinitura.name | uppercase}}</h2>
																<p v-if="selFinitura">
																		{{selFinitura.desc}}
																</p>
																<h3 v-if="selColore">Colore: {{selColore.idClr!='-1' ? selColore.name : selColore.name+selColore.ral }}</h3>
																<h3 v-if="selManto">Modello: {{selManto.name}}</h3>

																<h3 v-if="selOptions.length>0" class="mt-4">Opzioni Scelte</h3>
																<ul>
																		<li v-for="(opt, key) in selOptions" :key="key">{{opt.name}}
																				<span v-if="opt.selWidth"><br>Larghezza: {{ opt.width[opt.selWidth].name }}</span>
																				<span v-if="opt.selAlign"><br>Allineamento: {{opt.align[opt.selAlign].name}}</span>
																				<span v-if="opt.selHandle"><br>Maniglia: {{opt.handle[opt.selHandle].name}}</span>
																				<span v-if="opt.selModel"><br>Modello: {{opt.selModel.Descrizione}}</span>
																		</li>
																</ul>
														</v-col>
												</v-row>
										</v-container>
								</v-card-text>
						</v-card>

				</v-col>
		</v-row>
</v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
		props: {
		},
		data: () => ({
				//
		}),
		computed: mapState(['currentStep', 'renderSvg', 'width', 'height', 'left_space', 'right_space', 'architrave', 'depth', 'selManto', 'selFloor', 'selWall', 'selFinitura', 'selColore', 'selOptions', 'selTipologia']),
}
</script>