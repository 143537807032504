<template>
	<v-app>
		<v-stepper v-model="currentStep" class="mb-10">
			<v-stepper-header class="align-center px-5">
				<v-btn
					outlined
					rounded
					color="primary"
					@click="prevStep"
					:disabled="currentStep <= 1"
					><v-icon>mdi-chevron-left</v-icon
					><span class="hide-mobile"> Indietro</span></v-btn
				>
				<v-spacer></v-spacer>
				<template v-for="(step, n) in steps">
					<v-stepper-step
						:key="`${n + 1}-step`"
						:complete="currentStep > n + 1"
						:step="n + 1"
						>{{ step.label }}</v-stepper-step
					>
					<v-divider v-if="n + 1 < steps.length" :key="n + 1"></v-divider>
				</template>
				<v-spacer></v-spacer>
				<v-btn
					v-if="currentStep < steps.length"
					:loading="waiting"
					:disabled="!isValid"
					outlined
					rounded
					color="primary"
					@click="nextStep"
				>
					<span class="hide-mobile">Avanti </span
					><v-icon>mdi-chevron-right</v-icon>
				</v-btn>
				<DialogForm v-else @compiled="downloadPDF"></DialogForm>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<Step1 
						@stepIsValid="stepIsValid"
						@waitingBtn="waitingBtn"
						@updateSvg="renderSvg"
					></Step1>
				</v-stepper-content>
				<v-stepper-content step="2">
					<Step2
						@stepIsValid="stepIsValid"
						@waitingBtn="waitingBtn"
						@updateSvg="renderSvg"
					></Step2>
				</v-stepper-content>
				<v-stepper-content step="3">
					<Step3 @waitingBtn="waitingBtn" @updateSvg="renderSvg"></Step3>
				</v-stepper-content>
				<v-stepper-content step="4">
					<Step4 @updateSvg="renderSvg"></Step4>
				</v-stepper-content>
				<v-stepper-content step="5">
					<Step5 @updateSvg="renderSvg"></Step5>
				</v-stepper-content>
				<v-stepper-content step="6">
					<Step6></Step6>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
		<DialogLoader ref="dialogLoader"></DialogLoader>

		<v-footer class="font-weight-small">
			<v-col class="text-center" cols="12">
				&copy; {{ new Date().getFullYear() }} — CARINI S.r.l.<br />
				Via della Costituzione 15 - 52048 Monte San Savino (AREZZO) - Toscana<br />
				Tel: 0575 810044 - Fax: 0575 849195 - P.Iva: 01595300516<br />
				Numero Verde: 800903575
			</v-col>
		</v-footer>
	</v-app>
</template>

<script>
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import Step6 from "./components/Step6";
import DialogForm from "./components/DialogForm";
import DialogLoader from "./components/DialogLoader";
import { mapState } from "vuex";

export default {
	name: "App",
	components: {
		DialogForm,
		DialogLoader,
		Step1,
		Step2,
		Step3,
		Step4,
		Step5,
		Step6,
	},
	data: () => ({
		steps: [
			{ label: "Tipologie" },
			{ label: "Dimensioni" },
			{ label: "Finiture" },
			{ label: "Colori" },
			{ label: "Opzioni" },
			{ label: "Preventivo" },
		],
		isValid: false,
		quotationSent: false,
		waiting: false,
	}),
	computed: mapState(["currentStep"]),
	methods: {
		prevStep() {
			this.$store.commit("decrementStep");
		},
		nextStep() {
			if (this.currentStep == 2){
				this.getFiniture();
				this.renderSvg();
			}
			if (this.currentStep == 1 && !this.$store.state.width && !this.$store.state.height ) this.isValid = false; // imposto disattivato il pulsante avanti  al secondo step
			this.$store.commit("incrementStep");
		},
		stepIsValid(howIs) {
			this.isValid = howIs;
			if (howIs == "-1") {
				this.isValid = false;
			}
		},
		waitingBtn(howIs) {
			this.waiting = howIs;
		},
		async getFiniture(){
			let FinColsResp = await window.axios.get(
				this.apiRoot + "finiture?" + this.$store.state.urlParams + "&idTipologia=" + this.$store.state.selTipologia.idType
			);
			this.$store.commit("updateFiniture", FinColsResp.data);
		},
		async renderSvg() {
			this.$root.$dialogLoader.show("Attendere prego...", {});

			let postData = {
				w: this.$store.state.width,
				h: this.$store.state.height,
				l: this.$store.state.left_space,
				a: this.$store.state.architrave,
				r: this.$store.state.right_space,
				d: this.$store.state.depth,
				tipologia: this.$store.state.selTipologia,
				name: this.$store.state.selManto.name,
				disegnoManti: this.$store.state.selManto.disegno,
				idManto: this.$store.state.idModel,
				finitura: this.$store.state.selFinitura,
				colore: this.$store.state.selColore.hex,
				idColore: this.$store.state.selColore.idClr,
				coloreImg: this.$store.state.selColore.coloreIMG,
				wall: this.$store.state.selWall,
				floor: this.$store.state.selFloor,
				options: this.$store.state.selOptions,
			};
			let svgResp = await window.axios.post(this.apiRoot + "render", postData);
			this.$store.commit("updateRenderSvg", svgResp.data);
			// console.log("render: " + svgResp.data);
			this.$root.$dialogLoader.hide();
		},
		async downloadPDF(postData) {
			this.$root.$dialogLoader.show("Invio dell'email in corso...", {});

			//converto l'svg in png per inserirlo sul preventivo
			let rand = Math.random().toString(36).substring(7);
			let filename = Math.round(new Date().getTime() / 1000) + rand;
			window.axios.post(this.apiRoot + "render/svg2png", {
				svg: this.$store.state.renderSvg,
				filename: filename,
			});

			//invio la richiesta di preventivo al backend
			let tmpTipologia = this.$store.state.selTipologia;
			let tmpFinish = this.$store.state.selFinitura; //rimuovo dati inutili al fine del preventivo
			delete tmpFinish.colori;
			delete tmpFinish.pattern;
			let tmpColor = this.$store.state.selColore; //rimuovo dati inutili al fine del preventivo
			delete tmpColor.coloreIMG;
			delete tmpColor.pattern;
			let tmpWall = this.$store.state.selWall;
			if (typeof tmpWall.base64 != "undefined") delete tmpWall.base64;
			let tmpFloor = this.$store.state.selFloor;
			if (typeof tmpFloor.base64 != "undefined") delete tmpFloor.base64;
			let tmpOptions = this.$store.state.selOptions;
			tmpOptions.forEach((opt, key) => {
				if (typeof opt.modelli != "undefined") delete tmpOptions[key].modelli;
				if (typeof opt.selModel != "undefined")
					delete tmpOptions[key].selModel.immagine;
			});

			postData.selData = {
				width: this.$store.state.width,
				height: this.$store.state.height,
				left_space: this.$store.state.left_space,
				architrave: this.$store.state.architrave,
				right_space: this.$store.state.right_space,
				depth: this.$store.state.depth,
				selManto: JSON.stringify(this.$store.state.selManto),
				selFloor: JSON.stringify(tmpFloor),
				selWall: JSON.stringify(tmpWall),
				selFinitura: JSON.stringify(tmpFinish),
				selTipologia: JSON.stringify(tmpTipologia),
				selColore: JSON.stringify(tmpColor),
				selOptions: JSON.stringify(tmpOptions),
				imageUrl: filename + ".png",
			};

			// console.log(postData.selData.selFinitura)

			try {
				let response = await window.axios.post(
					this.apiRoot + "quotations",
					postData
				);
				if (response.data.status) {
					this.quotationSent = true;
				}
			} catch (e) {
				console.log(e);
			}

			this.$root.$dialogLoader.stop(
				new Object({ color: "success", timeout: 5000 }),
				() => {
					//console.log("var: "+this.quotationSent)
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							if (this.quotationSent)
								resolve(
									"Richiesta inviata! Controlla la tua posta per scaricare il PDF!"
								);
							else
								reject(
									"Errore! Riprova oppure contattaci ai recapiti sotto indicati"
								);
						}, 3000);
					});
				}
			);
		},
	},
	mounted() {
		this.$root.$dialogLoader = this.$refs.dialogLoader; //se metti l'attributo ref sul tag puoi richiamarlo da dentro qualsiasi componente
	},
};
</script>

<style>
/*trick per evitare che si veda lo sbordo dell'svg*/
html,
body,
svg {
	height: 100%;
}
div.svgContainer {
	text-align: center;
}
svg {
	max-height: 800px;
}
/*fine trick */

.v-stepper__header {
	z-index: 10;
	position: fixed;
	width: 100%;
	background: white;
}
.v-stepper__items {
	margin-top: 70px;
}
.scroll {
	overflow-y: scroll;
	max-height: 600px;
}

.beautyRadio {
	position: relative;
}
.beautyRadio .v-radio {
	position: absolute;
	top: 15px;
	left: 15px;
}
@media only screen and (max-width: 959px) {
	.v-stepper__header .v-btn {
		height: initial !important;
		min-width: initial !important;
		padding: 0 !important;
	}
	.hide-mobile {
		display: none;
	}
}
</style>

<style scoped>
@media only screen and (max-width: 959.98px) {
	.v-stepper__header::v-deep{
		height: 122px;
	}
	.v-stepper__items::v-deep{
		margin-top: 120px;
	}
  .v-stepper:not(.v-stepper--vertical)::v-deep .v-stepper__label {
    display: block;
  }
  .v-stepper:not(.v-stepper--vertical)::v-deep .v-stepper__step__step {
    display: none;
  }
}
</style>
